@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
/*@import url("assets/css/bootstrap.css");*/
html {
  scroll-behavior: smooth;
}

/* You can add global styles to this file, and also import other style files */

// @media (min-width: 992px){
//   .modal-lg {
//       max-width: 70% !important;
//   }
// }

// @media (min-width: 576px){
//   .modal-dialog {
//       max-width: 70% !important;
//       margin: 1.75rem auto;
//   }
// }
.cdk-overlay-pane {
  z-index: 10000 !important;
}

.modal {
  overflow: 100%;
}

.look {
  background: #f3f3f3;
}

/* .................................... PRINT VIEW  START..................................... */
.printThis {
  overflow: 100%;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

.row {
  margin-left: 1px;
  margin-right: 1px;
}

h5 {
  font-weight: bold;
}

p {
  font-size: medium;

}

.table-responsive {
  padding-left: 10px;
  padding-right: 10px;
}

.pdf-title {
  padding-top: 5px;
  padding-bottom: 2px;
  background: #427286;
  font-weight: bolder;
  font-size: 19px;

  h5 {
    color: #ffffff;
    font-size: 20px!important;
    font-weight: bold;
    text-align: center;
  }

  h6 {
    color: #ffffff;
    font-size: 17px!important;
    font-weight: bold;
    text-align: center;
  }
}

.pdf-title2 {
  padding-top: 5px;
  padding-bottom: 2px;
  background: #427286;
  font-weight: bolder;
  font-size: 21px;

  h5 {
    color: #ffffff;
    font-size: 21px;
  }
}

.table-wrp3 {
  font-size: 16px;
}

.pdf-sub {
  background: #639cb3;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 18px;

  h5,
  h6 {
    color: #ffffff;
    font-size: 18px;
  }
}

.pdf-sub1 {
  background: green;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 18px;

  h5,
  h6 {
    color: #ffffff;
    font-size: 16px;
  }
}

.table-striped .row:nth-of-type(odd) div {
  overflow-x: 100%;
  // background-color: #cbdcdf;
  background-color: #ecebe3;
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
}

.table-striped .row:nth-of-type(even) div {
  overflow-x: 100%;
  background: #ffffff;
  margin-bottom: 0px;
}

.table-wrp {
  font-size: 17px;
  font-weight: normal;
}

.table-wrp2 {
  font-size: 16px;
  font-weight: normal;
}

.info-wrp {
  font-size: 16px;
  // text-transform: capitalize;
  // font-weight: bold;
  /* padding-left: 5px; */
  color: green;
}

.td-wrp {
  font-size: 20px;
  // text-transform: capitalize;
  font-weight: bold;
  /* padding-left: 5px; */
  color: green;
}

textarea {
  /* box-sizing: padding-box; */
  // overflow:hidden;
  /* demo only: */
  color: green;
  font-weight: 500px;
  padding: 10px;
  width: 80%;
  font-size: 17px;
  margin-top: 50px;
  display: block;
  border-radius: 10px;
  border: 6px solid #556677;
}

.f-16 {
  font-size: 16px;
}

.f-15 {
  font-size: 15px;
}

.f-14 {
  font-size: 14px;
}

.f-13 {
  font-size: 13px;
}

.text-bold {
  // font-weight: bold;
  font-weight: 600;
}

.headerdivider {
  margin-top: 2px;
  margin-bottom: 2px;
}

hr {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}


.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}


.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
}

.form-control:focus {
  border-bottom-color: transparent;
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
  background-image: linear-gradient(to top, #4680ff 2px, rgba(70, 128, 255, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
}

.form-group {
  position: relative;
}

.form-group .form-control {
  padding-left: 0;
  padding-right: 0;
}

.form-group .floating-label {
  position: absolute;
  top: 11px;
  left: 0;
  font-size: 0.950rem;
  z-index: 1;
  cursor: text;
  transition: all 0.3s ease;
}

.form-group .floating-label + .form-control {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.form-group.fill .floating-label {
  top: -10px;
  font-size: 0.75rem;
  color: #4680ff;
}

.no-data-available {
  text-align: center;
}


.text-bold {
  font-weight: bold;
}

.row {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  /* border-bottom: 0.1px solid rgb(212, 212, 212); */
  //TODO: remove comment if creates problem elsewhere
}


.divider {
  color: rgb(68, 67, 67);
  border: 0.3px #eee solid;
  margin-top: 1px;
  margin-bottom: 1px;
}

.headerdivider {
  margin-top: 2px;
  margin-bottom: 2px;
}

/* ......................................................................................................... */


@media screen {
  #printSection {
    display: none;
  }
  #printThis {
    display: block;

    h3 {
      position: absolute;
      page-break-before: always;
      bottom: 0;
      right: 0;
    }

    h3::before {
      position: relative;
      bottom: -20px;
      counter-increment: section;
      content: counter(section);
    }
  }
}

@media print {
  @page {
    size: A4;
    size: 210mm 297mm;
    margin: 0;
    // margin: 0.5cm;
    // padding:0.25cm;
    .row {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-before: always;
      page-break-after: always;
    }
  }

  .row {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    // page-break-before: always;
    // page-break-after: always;
  }
  .list {
    display: table-row !important;
    -webkit-print-color-adjust: exact !important;
  }
  .list > div {
    display: table-cell !important;
    -webkit-print-color-adjust: exact !important;
  }

  body * {
    visibility: hidden;
    background: none !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  body.background-blue {
    background: #fff !important;
  }
  #printSection,
  #printSection * {
    visibility: visible;
    /* margin: 0; */
  }
  #printSection {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    min-height: 550px;
  }

  .doNotPrint {
    display: none !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .modal,
  .modal * {
    overflow: 100% !important;
  }
  .table-responsive {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pdf-title {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
    background: #427286 !important;
    font-weight: bolder !important;
    color: #fff !important;
    border: #e2e5e8 1px solid;
    font-size: 16px !important;
    // color: #ffffff !important;
    h5 {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold;
      text-align: center;
    }

    h6 {
      color: #fff !important;
      font-size: 12px !important;
      font-weight: bold;
      text-align: center;
    }

    -webkit-print-color-adjust: exact;
  }

  .row {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    /* border-bottom: 0.1px solid rgb(233, 233, 233) !important; */
    //TODO: remove comment if creates problem elsewhere
  }
  .pdf-title2 {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
    background: #427286 !important;
    color: #fff !important;
    font-weight: bolder !important;
    border: #e2e5e8 1px solid;
    font-size: 16px !important;
    // color: #ffffff !important;
    h5 {
      color: #fff !important;
      font-size: 14px !important;
    }

    -webkit-print-color-adjust: exact;
  }
  .pdf-sub {
    background: #427286 !important;
    font-size: 14px !important;
    font-weight: bolder !important;
    // color: #ffffff !important;
    h5,
    h6 {
      color: #fff !important;
      font-size: 14px !important;
    }

    border: #e2e5e8 1px solid;
    text-transform: capitalize !important;
    -webkit-print-color-adjust: exact;
  }
  .pdf-sub1 {
    background: #427286 !important;
    font-size: 14px !important;
    font-weight: bolder !important;

    h5 {
      color: green !important;
      font-size: 14px !important;
    }

    text-transform: capitalize !important;
    -webkit-print-color-adjust: exact;
  }
  .table-striped .row:nth-of-type(odd) div {
    overflow-x: 100% !important;
    background-color: #f9fbff !important;
    // border-bottom: #e2e5e8 1px solid;
    /* padding-top: 15px !important; */
    /* padding-bottom: 15px !important; */
    -webkit-print-color-adjust: exact;
  }
  .table-striped .row:nth-of-type(even) div {
    overflow-x: 100% !important;
    background: #ffffff !important;
    // border-bottom: #e2e5e8 1px solid;
    margin-bottom: 0px;
    -webkit-print-color-adjust: exact;
  }
  .table-wrp {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .table-wrp2 {
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .info-wrp {
    // text-transform: capitalize !important;
    font-size: 14px !important;
    // font-weight: bold !important;
    /* padding-left: 5px !important; */
    color: green;
  }
  .td-wrp {
    // text-transform: capitalize !important;
    font-size: 13px !important;
    font-weight: bold !important;
    /* padding-left: 5px !important; */
    color: green;
  }
  hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.watermark:after {
  content: attr(data-watermark);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 30%;
  right: 0;
  z-index: 1;

  color: #0d745e;
  font-size: 80px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  transform: rotate(-45deg);
  /* user-select: none; !* Non-prefixed version for chorme, opera and*!
   -ms-user-select: none; !* Internet Explorer, Edge *!
   -moz-user-select: none; !* Firefox *!
   -khtml-user-select: none; !* Konqueror HTML *!
   -webkit-touch-callout: none; !* iOS Safari *!
   -webkit-user-select: none; !* Safari *!*/
  width: 595px;
}

hr {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media print and (min-height: 120vw) and (max-height: 150vw) { /* legal-size styling */
  .chrome.container::before {
    content: "A4";
  }
}

@media print and (min-height: 100vw) and (max-height: 120vw) { /* A4 styling */
  .chrome.container::before {
    content: "A4";
  }
}

@media print and (min-height: 80vw) and (max-height: 100vw) { /* letter-size styling */
  .chrome.container::before {
    content: "A4";
  }
}

@media print and (min-height: 120vw) and (max-height: 150vw) { /* legal-size styling */
  .apple.container::before {
    content: "LEGAL";
  }
}

@media print and (min-height: 100vw) and (max-height: 120vw) { /* A4 styling */
  .apple.container::before {
    content: "A4";
  }
}

@media print and (min-height: 80vw) and (max-height: 100vw) { /* letter-size styling */
  .apple.container::before {
    content: "LETTER";
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #println {
    display: none;
  }
}

.dwnpdf > .row {
  page-break-after: always !important;
}

// .....................................................  MY STYLES  ................................................................................//
.body {
  font-size: 1.2rem;
  font-weight: 800;
}

// .btn-success {
// color: #fff;
// background-color: #02ad09;
// border-color: #4e8015;
// }
// .btn-info {
// color: #fff;
// background-color: #009688;
// border-color: #046f65;
// }
// .btn-warning {
//   color: #fff;
//   background-color: #ffba57;
//   border-color: #eaa036;
// }
// .btn-danger {
//   color: #fff;
//   background-color: #ef0000;
//   border-color: #9f2121;
// }
// .close{
//   margin-top: -21px;
//   margin-right: -20px;
// }
// .btn-primary {
//   color: #fff;
//   background-color: #0547d9;
//   border-color: #1b3d89;
// }
// .btn-secondary {
//   color: #fff;
//   background-color: #6c757d;
//   border-color: #585c5f;
// }
// .btn-imagine {
//   color: #fff;
//   background-color: #673ab7;
//   border-color: #701188;
// }
.form-group .form-control {
  padding-left: 10px;
  padding-right: 10px;
  font-size: larger;
  color: #3d40ee;
  font-weight: 600;
}

// .modal-body {
//   background: #e9ecef87;
//   width: 1080px !important;
// }

.pcoded-navbar .pcoded-inner-navbar li {
  background: #0000000d;
  margin: 5px;
}

.pcoded-navbar .pcoded-inner-navbar li :active {
  background: #00000017;
}

// @media only screen and (max-width: 991px){
//   .pcoded-navbar {
//     margin-left: -10px;
//     position: absolute;
//     height: calc(100% - 50px);
//   }
// }

// .............................................................................................  MY STYLES  ............................................................................................//

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  border: none !important;
}

// input[type="text"][disabled] {
//    color: #861b1b;
//    background: #e0cdcd;
// }
input[disabled] {
  color: #861b1b;
  background: #e0cdcd;
}

ng-select {
  font-weight: bolder;
}

textarea.form-control {
  margin: 0;
}

.p_info {
  font-size: 0.85em;
  // padding: 0.94em;
  font-family: Arial, Helvetica, sans-serif;
}

.p_info > h5 {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.p_info > table {
  // background-color: red;
  width: 100%;
  margin-bottom: 1.85em;
  // border: 1px solid black;
}

.p_info > table > tr > th {
  text-align: left;
  border: 1px solid black;
}

.p_info > table > tr > td {
  padding-left: 0.4em;
  border: 1px solid black;
  height: 35px;
}

.p_info > table > tr.borderless > td {
  border: none;
  // background-color: green;
}

.p_info > table > tr > td.fit-content {
  width: auto;
}

.btn-rnd {
  border-radius: 74%;
  padding: 7px;
  margin: 0px;
  // width: 45px;
  // height: 45px;
}

.num-rnd {
  border: 1px solid black;
  border-radius: 74%;
  width: 45px;
  height: 45px;
  background: #000000;
  padding: 3px;
}


.form-group {
  position: relative;
  margin-bottom: 0.5rem;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
}

.form-control:focus {
  border-bottom-color: transparent;
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
  background-image: linear-gradient(
      to top,
      #4680ff 2px,
      rgba(70, 128, 255, 0) 2px
  ),
  linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
}

.form-group {
  position: relative;
}

.form-group .form-control {
  padding-left: 0;
  padding-right: 0;
}

.form-group .floating-label {
  position: absolute;
  top: 11px;
  left: 0;
  font-size: 0.95rem;
  z-index: 1;
  cursor: text;
  transition: all 0.3s ease;
}

.form-group .floating-label + .form-control {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.form-group.fill .floating-label {
  top: -10px;
  font-size: 0.75rem;
  color: #4680ff;
}

.label {
  font-size: 0.625em;
  background: #607D8B;
  color: #dedede;
  font-weight: bold;
  height: 25px;
  text-transform: uppercase;
}

.light-navy-blue {
  background-color: #34495e;
  color: #fff;
}

.btn.btn-navy-blue,
button:hover.btn.btn-navy-blue {
  background-color: #34495e;
  color: #fff;
}

button:hover.btn.btn-outline-navy-blue {
  background-color: #34495e;
  color: #fff;
}

.btn.btn-outline-navy-blue {
  background-color: transparent;
  color: #34495e;
}

.search-hero > input {
  background: #f3f3f3;
}

.table thead tr th {
  background-color: #374b54;
  color: #fff;
  font-weight: 500;
}

.table-bordered tbody tr td, .table-bordered tbody tr th {
  padding: 6px;
  border: 1px solid #dddddd;
}

.table-bordered tbody tr th {
  font-weight: bolder;
}

.no-data-available {
  text-align: center;
}

.badge-finalised {
  background: #1f75fe;
  border-color: #ffecd0;
  color: #fff;


}

.badge-draft {
  background: #daa520;
  border-color: #ffecd0;
  color: #fff;

}


.ql-editor {
  font-size: 18px;
}

.ql-editor p {
  font-size: 18px;
}

.ql-editor u {
  font-size: 21px;
}

.ql-snow .ql-editor h4 {
  font-size: 18px;
}

.modal .modal-content .modal-body {
  padding: 0px;
  // width: 1080px !important;
}

.quill-view-html {
  width: 100%;
}

.curves {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

// @media (max-width: 480px) {
//   .mobile{margin: 0px !important;}
//   .mobile2{padding: 0px !important;}
//  }


/*   Custom Css */
body {
  background-color: #f8f9fa;
}

.card {
  border: 0 !important;
  border-radius: 1rem !important;
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05) !important;
}

.card-plain {
  background-color: transparent !important;
  box-shadow: none !important;
}

.card-header {
  background-color: transparent;
  border: none !important;
}

.h-100 {
  height: 100% !important;
}

.text-blue {
  color: #030d7a;
}

.cursor {
  cursor: pointer;
}


a {
  color: inherit !important;
}

.bg-text {
  bottom: 45%;
  position: absolute;
  color: #0d745ea8 !important;
  font-size: 80px;
  font-weight: 600;
  display: grid;
  justify-content: center;
  align-content: center;
  opacity: 0.2;
  transform: rotate(-45deg);
  user-select: none; /* Non-prefixed version for chorme, opera and*/
  -ms-user-select: none; /* Internet Explorer, Edge */
  -moz-user-select: none; /* Firefox */
  -khtml-user-select: none; /* Konqueror HTML */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
}


@media print {
  .bg-text {
    display: none;
  }

  .pdf-title {
    background: #ecf0f5 !important;

    h5, h6 {
      color: #000000 !important;
    }

    h5 {

      font-size: 20px!important;
    }

    h6 {
      font-size: 17px!important;
    }
  }
}

@page {
  size: auto;
  margin: 30px 0;
}

.btn {
  z-index: 1 !important;
}

#printThis {
  padding: 0 30px;
}


.modal-body {
  background-color: white !important;
  // width: 1080px !important;
  // height: 1px !important;
}

.ql-editor {
  font-size: 15px !important;
}

.ql-editor p {
  font-size: 15px !important;
}

.table-wrp3 {
  font-size: 15px !important;
}

.table-wrp3 p {
  font-size: 15px !important;
}


// * {
//   font-family: "poppins", sans-serif;
//   word-spacing: 1px;
// }
* {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 14px;
  word-spacing: 1px;
}
.pdf-sub_new {
  background: #639cb3;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 18px;

  h5,
  h6 {
    color: #ffffff;
    font-size: 18px;
  }
}
.gl-sign-right {
  text-align: right; /* Align text to the right */
}

// @media (min-width: 576px){
//   .modal-dialog {
//     // max-width: 500px;
//     max-width: 1280px !important;
//     margin: 1.75rem auto;
// }
// }
// @media (min-width: 992px){
//   .modal-lg, .modal-xl {
//     width: 1280px !important;
//     // max-width: 800px;
// }
// }

// @media (min-width: 300px) and (max-width: 768px) {
//   .modal .modal-content .modal-body .table-striped {
//     padding: 0px;
//     width: 1080px !important;
//   }
//   .modal-body {
//     background-color: white !important;
//     width: 1080px !important;
//     // height: 1px !important;
//   }
//   .table-striped{
//     width: 1080px !important;

//   }
//   .row{
//     width: 100% !important;

//   }
//   .col-sm-7{
//     width: 70% !important;

//   }
// }